import React, { useEffect, useState } from 'react';
import './footer.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import LineIcon from 'react-lineicons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

function Footer() {

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <h3>Orange-Personal</h3>
                    </div>
                    <div className="col-lg-4">

                            <span className="linkedin">
                                <a href="http://linkedin.com/in/alexander-rosin-396a9b3a/" target="_blank"><LineIcon name="linkedin-original" /></a>
                            </span>
                            <span className="facebook">
                                <a href="https://www.facebook.com/OrangePersonal/">
                                    <LineIcon name="facebook-original" />
                                </a>
                            </span>
                            <a href="https://www.xing.com/profile/alexander_rosin" target="_blank">
                                    <img src="https://dev.xing.com/assets/xws/dev/logo_rules/XNG_Sharebutton_v02-80d4fefd22918014c17b635c67390054a5933ed51c4a075b00f5dc69b4cb96d9.png" alt="Xing"/>
                            </a>


                        {/*<p>Full suite enable teams to develop unique search and discovery experiences.</p>
                        <div className="icon-links icon-social icon-links-grid social-colors">
                            <a className="facebook"><i className="icon-facebook"></i></a>
                            <a className="twitter"><i className="icon-twitter"></i></a>
                            <a className="instagram"><i className="icon-instagram"></i></a>
                            <a className="pinterest"><i className="icon-pinterest"></i></a>
                        </div>
                        */}
                    </div>
                    <div className="col-lg-4">
                        {/*
                        <h3>Resources</h3>
                        <ul className="icon-list icon-line">
                            <li><FontAwesomeIcon icon={faMinus} /><a href="#">Partners and advertising</a></li>
                            <li><FontAwesomeIcon icon={faMinus} /><a href="#">About us and the company</a></li>
                            <li><FontAwesomeIcon icon={faMinus} /><a href="#">Services and projects</a></li>
                            <li><FontAwesomeIcon icon={faMinus} /><a href="#">Contact us online</a></li>
                        </ul>
                        */}
                    </div>
                    <div className="col-lg-4">
                        {/*
                        <ul className="text-list text-list-line">
                            <li><b>Address</b><hr /><p>Suarezstr. 57, 14057, Berlin</p></li>
                            <li><b>Email</b><hr /><p>info@orange-personal.de</p></li>
                            <li><b>Phone</b><hr /><p>+49 176 000 00 000</p></li>
                            <li><b>Opening hours</b><hr /><p>8am-5pm Mon - Fri</p></li>
                        </ul>
                        */}
                    </div>
                </div>
            </div>
            <div className="footer-bar">
                <div className="container">
                    <span>© 2007-2021 Orange-Personal - Technology And Business.</span>
                </div>
            </div>
        </footer>
    )
}


export default Footer;