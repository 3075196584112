import React from 'react';
import Header from '../../components_orange/header/Header';

import ContactForm from '../../components_orange/contact/Contact';
import Footer from '../../components_orange/footer/Footer';
//import './App.css';

function Service() {
  //const bab = new Babylon();
  return (
    <div className="container-fluid">
        <Header />
        <ContactForm detailed={true} showMap={true} />

        <Footer />
    </div>
  );
}

export default Service;