import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import Babylon from '../Babylon';
import './header.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import Select from 'react-select'


import CookieBanner from 'react-cookie-banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import logo from './img/logo3.png';
import deFlag from './img/de.png';
import enFlag from './img/en.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [menuClasses, setMenuClasses] = useState("container-fluid orange");
  const toggle = () => setIsOpen(!isOpen);
  const cookies = new Cookies();

  let lang = cookies.get('language');

  if (typeof(lang) == "undefined") {
    lang = "de";
    cookies.set('language', lang , { path: '/' });
  }

  console.log("LANG:", lang);

  let pl = { value: 'en', label: 'EN', lang: "/en.png" };

  let options = [
    { value: 'de', label: 'DE', lang: "/de.png" },
    //{ value: 'en', label: 'EN', lang: "/img/en.png" }
  ];
  if(lang == "de") {
    pl = { value: 'de', label: 'DE', lang: "/de.png" };
    options = [
      //{ value: 'de', label: 'DE', lang: "/img/de.png" },
      { value: 'en', label: 'EN', lang: "/en.png" }
    ];
  }


  useEffect(() => {
    //const favicon = document.getElementById("favicon");
    //document.title = "Orange-Personal";
    //favicon.href = "/favicon_orange.ico";
    //console.log("favicon:", favicon);

    window.onscroll = () => {
      if(window.pageYOffset > 99) {
        setMenuClasses("container-fluid orange fixed");
      } else {
        setMenuClasses("container-fluid orange");
      }

      console.log(window.pageYOffset);

    }
  });



  const dot = (lang = pl.lang) => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundImage: `url(${lang})`,
      borderRadius: 0,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 12,
      width: 18,
      position: 'absolute',
      left: 0,
      zIndex: 999,
    },
  });

  const colourStyles = {
    input: styles => ({ ...styles, ...dot() }),
  }

  const handleChange = selectedOption => {
    //this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    cookies.set('language', selectedOption.value , { path: '/' });
    //setLang(selectedOption.value);

    console.log(cookies.get('language'));
    window.location.reload();
  };

  let companyText = "Company";
  let contactText = "Contact";
  if(lang == "de") {
    companyText = "Unternehmen";
    contactText = "Kontakt";
  }

  return (
    <header className="header-orange">
      <CookieBanner
        message="Yes, we use cookies. If you don't like it change website, we won't miss you!"
        onAccept={() => {}}
        cookie="user-has-accepted-cookies" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 header-top">
            <div className="menu-brand">
              <a href="/">
                <img src={logo} alt="" />
                <span className="black">range</span>
                <span className="dev">Personal</span>
                <span className="search">searching for talents</span>

              </a>
            </div>
            <canvas id="renderCanvas" touch-action="none"></canvas>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className={menuClasses}>
        <div className="row2">
          <div className="col-sm-12 main-menu">
            <Navbar light expand="md">
              <NavbarBrand href="/">
                <FontAwesomeIcon icon={faHome} />
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <NavLink href="/about/">About</NavLink>
                  </NavItem>
                   {/*
                  <NavItem>
                    <NavLink href="/jobs/">Jobs</NavLink>
                  </NavItem>
                  /*}
                  {/*
                  <NavItem>
                    <NavLink href="/candidate/">Bewerber</NavLink>
                  </NavItem>
                    */}
                  <NavItem>
                    <NavLink href="/company/">{companyText}</NavLink>
                  </NavItem>
                  {/*
                  <NavItem>
                    <NavLink href="/contact/">{contactText}</NavLink>
                  </NavItem>
                  */}
                  <NavItem>
                    <NavLink href="/impressum/">Impressum</NavLink>
                  </NavItem>
                  {/*
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Options
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        Option 1
                      </DropdownItem>
                      <DropdownItem>
                        Option 2
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        Reset
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  */}
                </Nav>
                <NavbarText>
                  <span className="language-switcher">
                    <Select
                      options={options}
                      styles={colourStyles}
                      onChange={handleChange}
                      defaultValue={pl}
                    />
                  </span>
                </NavbarText>
              </Collapse>
            </Navbar>


          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
