import React, { useEffect, useState } from 'react';
import './benefit.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Collapse, Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import logo from './img/box.png';

function Benefit() {

    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const toggle = (flag) => {

        if (flag === 1) {
            setIsOpen1(!isOpen1);
        }

    };
    let class1 = '';
    let class2 = '';
    let class3 = '';

    if (isOpen1) {
        class1 = ' active';
    }
    if (isOpen2) {
        class2 = ' active';
    }
    if (isOpen3) {
        class3 = ' active';
    }

    return (
        <section className="benefit">
            <div className="container">
                <div className="row align-items-center fade-bottom" data-anima="" data-time="1000">
                    <div className="col-lg-6">
                        <div className="title">
                            <p>Vorteile</p>
                            <span>You should choose</span> <span className="orange"> Orange</span><span> - </span><span className="green">Personal</span>

                        </div>
                        <p>
                            Als unabhängiger Beratungsdienstleister, unterstützt Sie Orange Personalberatung bei der Suche nach qualifizierten Mitarbeitern. <br />
                            Orange Personalberatung sucht für Sie nach geeigneten Bewerbern unter Zuhilfenahme von aktuellen Datenbanken, Netzwerken und durch Anzeigenschaltungen sowie durch Direktansprache.
                        </p>
                        <hr className="space-sm" />
                        <div className="menu-entry">
                            <Button color="primary" className={class1}
                                onClick={() => {
                                    setIsOpen1(!isOpen1);
                                    if (!isOpen1) {
                                        setIsOpen2(false);
                                        setIsOpen3(false);
                                    }
                                }}
                            >
                            <FontAwesomeIcon icon={faAngleRight} />Direct Search/ Executive Search</Button>
                            <Collapse isOpen={isOpen1}>
                                <p>
                                Da es bei der Besetzung von Positionen mit höchstem Anforderungsprofil oder mit quantitativ begrenztem  Bewerbermarkt oft nicht möglich ist, die Führungskräfte und Spezialisten über Anzeigen zu erreichen,
sucht Orange Personal diese über die gezielte Ansprache (Direct Search).
                                </p>
                            </Collapse>
                        </div>
                        <div className="menu-entry">
                            <Button color="primary" className={class2}
                                onClick={() => {
                                    setIsOpen2(!isOpen2);
                                    if (!isOpen2) {
                                        setIsOpen1(false);
                                        setIsOpen3(false);
                                    }
                                }}
                            >
                            <FontAwesomeIcon icon={faAngleRight} />
                            Die Direktansprache (ausführlich)</Button>
                            <Collapse isOpen={isOpen2}>
                                <p>
                                Ihr <b>Unternehmen</b> hat Bedarf an hochqualifizierten Spezialisten oder Führungskräften in engen Nischenmärkten und kann diese über die üblichen Rekrutierungswege nicht für eine Mitarbeit gewinnen? Sie haben jedoch Rekrutierungsbedarf, den Sie nicht öffentlich kommunizieren möchten ?
                                </p>
                                <p>
                                Mit der Methode des Direct Search identifiziert Orange Personal insbesondere Kandidaten, die aufgrund ihres Erfolges von sich aus nicht den Anstoß haben, in eine andere Position zu wechseln und deshalb am Arbeitsmarkt nicht aktiv auf der Suche sind
                                </p>
                            </Collapse>
                        </div>
                        <div className="menu-entry">
                            <Button color="primary" className={class3}
                                onClick={() => {
                                    setIsOpen3(!isOpen3);
                                    if (!isOpen3) {
                                        setIsOpen1(false);
                                        setIsOpen2(false);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleRight} />Vertraulichkeit und Zuverlässigkeit</Button>
                            <Collapse isOpen={isOpen3}>
                                <p>
                                Kernkompetenzen sind absolute Vertraulichkeit und Zuverlässigkeit.
                                Besonderen Wert legen wir auf eine persönliche und individuelle Beratung.
                                </p>
                                <p>
                                Dafür möchten wir Ihr Unternehmen auch gern vor Ort kennenlernen. Das hilft, die optimale Personalauswahl nicht nur nach fachlicher und persönlicher Qualifikation, sondern auch unter Berücksichtigung des gesamten Umfelds vorzunehmen.
                                </p>
                                <p>
                                Das Wissen um die Produkte und Dienstleistungen Ihres Unternehmens und deren Merkmale,
                                sind die wichtigste Grundlage für eine erfolgreiche Vermittlung und nachhaltige Positionierung neuer Mitarbeiter.
                                </p>
                            </Collapse>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img className="img-fluid" src={logo} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Benefit;
