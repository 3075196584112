import React from 'react';
import Header from '../../components_orange/header/Header';

import JobsBox from '../../components_orange/jobsBox/JobsBox';
import ContactForm from '../../components_orange/contact/Contact';
import Breadcrump from '../../components_orange/breadcrump/Breadcrump';
import Inbox from '../../components_orange/inbox/Inbox';
import Card from '../../components_orange/card/Card';

import Footer from '../../components_orange/footer/Footer';
import './App.scss';

function Job() {

  return (
    <div className="container-fluid">
        <Header />
        <div className="construction2">
            <div className="container">
              <Breadcrump />
              {/*<Inbox />*/}
              <Card />
              <JobsBox />


            </div>
        </div>
        <Footer />
    </div>
  );
}

export default Job;
