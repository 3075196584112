import React, { useEffect, useState } from 'react';
import './me.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import LineIcon from 'react-lineicons';
import me from './img/me.jpg';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

function Me() {

    const [isOpen1, setIsOpen1] = useState(false);

    let class1 = 'icon-links inactive';
    let class2 = 'caption';
    let class3 = 'cnt-box cnt-box-team';
    if (isOpen1) {
        class1 = 'icon-links active text-center';
        class2 = 'caption height-200';
        class3 = 'cnt-box cnt-box-team margin-60';
    }
    return (
                    <div className={class3}
                        onMouseOver={() => {
                            setIsOpen1(!isOpen1);
                        }}
                        onMouseOut={() => {
                            setIsOpen1(!isOpen1);
                        }}
                    >
                        <img src={me} alt="" />
                        <div className={class2}>
                            <h2 className="text-center">Alexander Rosin</h2>
                            <div className="role text-center">Personalberater</div>
                            <div className={class1}>
                                <a href="https://www.facebook.com/OrangePersonal/" target="_blank"><LineIcon name="facebook-filled" /></a>
                                <a href="http://linkedin.com/in/alexander-rosin-396a9b3a/" target="_blank"><LineIcon name="linkedin-original" /></a>
                                <a href="https://www.xing.com/profile/alexander_rosin" target="_blank">
                                    <img src="https://dev.xing.com/assets/xws/dev/logo_rules/XNG_Sharebutton_v02-80d4fefd22918014c17b635c67390054a5933ed51c4a075b00f5dc69b4cb96d9.png" alt="Xing"/>
                                </a>
                            </div>

                            <p className={class1}>
                                Mehr als zehn Jahre Erfahrung in der Rekrutierung von Fach- und Führungskräften
                            </p>
                        </div>
                    </div>

    );
}

export default Me;