import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import Breadcrump from '../../components_orange/breadcrump/Breadcrump';
import './jobheader.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import background from "./img/header.jpg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

function JobHeader(props) {
    const { h1, h2, name } = props;

    return (
        <div className="header-image ken-burn-center light" style={{ backgroundImage: `url(${background})` }} data-parallax="true" data-natural-height="500" data-natural-width="1920" data-bleed="0" data-offset="0">
            <div className="container">
                <h1>{h1}</h1>
                <h2>{h2}</h2>
                <div className="align-items-end flex-column d-none d-md-block">
                    <Breadcrump name={name}/>
                </div>

            </div>
        </div>
    );
}

export default JobHeader;
