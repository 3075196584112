import React, { useEffect, useState } from 'react';

import './breadcrump.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function Breadcrump(props) {
    let {name} = props;
    useEffect(() => {

    });
    return (
        <div className="breadcrump">
	    <Breadcrumbs aria-label="breadcrumb">
	    	<Link color="inherit" href="/" onClick={handleClick}>
        		Home
	        </Link>
      		<Typography color="textPrimary">{name}</Typography>
	   </Breadcrumbs>
        </div>
    );

}

export default Breadcrump;
