import React from 'react';
import Header from '../../components_orange/header/Header';
import TopBanner from '../../components_orange/topBanner/TopBanner';
import FancyBox from '../../components_orange/fancyBox/FancyBox';
import About from '../../components_orange/about/About';
import CoreBox from '../../components_orange/core/CoreBox';
import Contact from '../../components_orange/contact/Contact';
import Benefit from '../../components_orange/benefit/Benefit';
import Footer from '../../components_orange/footer/Footer';
import Me from '../../components_orange/me/Me';
import CardSmall from '../../components_orange/cardsmall/CardSmall';
import './App.scss';

function Home() {
  //const bab = new Babylon();
  return (
    <div className="container-fluid home">
        <Header />
        {/*<TopBanner />*/}
        <About />

        <div className="container-fluid hp-cardsmall">
          <CardSmall />
        </div>

        {/*<Contact />*/}
        <Benefit />
        <Footer />
    </div>
  );
}

export default Home;