import React from 'react';
import Header from '../../components_orange/header/Header';


import JobHeader from '../../components_orange/jobheader/JobHeader';

import Footer from '../../components_orange/footer/Footer';
import './App.scss';

//import img1 from './img/image-1.jpg';


function Candidate() {

  return (
    <div className="container-fluid candidate">
        <Header />
        <JobHeader
          h1="foo1"
          h2="foo2"
          name="Bewerber"
        />
        <div className="foo" >
          <p>
          Orange Personal vermittel Sie als Fach- und Führungskräfte direkt in passende Unternehmen.
          </p>
          <p>
          Sind Sie in Ihrem Fach sehr engagiert, topfit und hochmotiviert sich einer neuen Herausforderung zu stellen?
          Sie möchten sich also gern hierfür beruflich verändern? Dann sollten wir schnell ins Gespräch kommen.
          Orange Personal unterstützt Sie gern bei der Realisierung Ihres beruflichen Weiterkommens und bringt die Anforderungsprofile
          der Beteiligten miteinander in Einklang. Hierbei ausschlaggebend ist das Wissen um Ihre Fähigkeiten und Qualifikationen.
          Dies stellt die Grundlage für ein erfolgreiches Bewerberinterview dar.
          </p>
          <p>
          In unserer Zusammenarbeit setzt Orange Personal immer auf die Nachhaltigkeit für alle Seiten.
          Idealerweise setzt sich Ihr neuer Arbeitgeber nicht nur aus Menschen, Produkten, Leistungen, Betriebsvorgängen,
          Gewinn und Umsatz zusammen, sondern pflegt auch die Unternehmensphilosohie in der Corporate Social Responsibility (CSR),
          da soziale Verantwortung von Unternehmen im Zusammenspiel der Gesellschaft im Wertschöpfungsprozess einen wichtigen Stellenwert einnimmt.
          </p>
          <p>
          Sind Sie bereit? Dann hilft Ihnen Orange Personal bei Ihrer Karriereplanung und dem Finden einer neuen Position.
          Damit Ihre individuellen Qualifikationen und beruflichen Ziele auch wirklich zu den Anforderungsprofilen der zu besetzenden Positionen der Kunden passen, führt
          Orange Personal mit Ihnen genaue und ausführliche Bewerbungsinterviews.
          </p>
            </div>
        <Footer />
    </div>
  );
}

export default Candidate;
