import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import './card.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import unt from './img/unternehmen.jpg';
import jobs from './img/jobs.jpg';
import about from './img/about.jpg';


const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  });

function CardSmall() {
    const classes = useStyles();
    return (
        <div className="container">
            <div className="row card-small">
                <div className="col-lg-4">
                    <Card className={classes.root}>
                        <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={unt}
                            title="Unternehmen"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Unternehmen
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Als unabhängiger Beratungsdienstleister, unterstützt Sie Orange Personalberatung bei der Suche nach qualifizierten Mitarbeitern.
                            </Typography>
                        </CardContent>
                        </CardActionArea>
                        <CardActions>
                        <Button size="small" color="primary">
                            <a href="/company/">Mehr ...</a>
                        </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="col-lg-4">
                    <Card className={classes.root}>
                        <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={about}
                            title="Über Orange Personal"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Über Orange Personal
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Unser Team besteht aus hervorragend ausgebildeten und motivierten Mitarbeitern, die absolute Experten auf ihrem Gebiet sind. Dank ihrer langjährigen Erfahrung in der Branche können sie jederzeit umfangreichen, erstklassigen Service für unsere Kunden anbieten.
                            </Typography>
                        </CardContent>
                        </CardActionArea>
                        <CardActions>
                        <Button size="small" color="primary">
                            <a href="/about/">Mehr ...</a>
                        </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="col-lg-4">
                    <Card className={classes.root}>
                        <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={jobs}
                            title="Offene Stellen"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                            Offene Stellen
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                            Kernkompetenzen sind absolute Vertraulichkeit und Zuverlässigkeit. Besonderen Wert legen wir auf eine persönliche und individuelle Beratung.
                            </Typography>
                        </CardContent>
                        </CardActionArea>
                        <CardActions>
                        <Button size="small" color="primary">
                            <a href="/jobs/">Mehr ...</a>
                        </Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default CardSmall;
