import React from 'react';
import Header from '../../components_orange/header/Header';
import TopBanner from '../../components_orange/topBanner/TopBanner';
import FancyBox from '../../components_orange/fancyBox/FancyBox';
import About from '../../components_orange/about/About';
import CoreBox from '../../components_orange/core/CoreBox';
import ContactForm from '../../components_orange/contact/Contact';
import Breadcrump from '../../components_orange/breadcrump/Breadcrump';
import Inbox from '../../components_orange/inbox/Inbox';
import Card from '../../components_orange/card/Card';
import Me from '../../components_orange/me/Me';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Footer from '../../components_orange/footer/Footer';
import './App.scss';

function AboutPage() {
  //const bab = new Babylon();
  return (
    <div className="container-fluid about">
        <Header />
            <div className="container content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="about-title">
                    {/* Unser Team besteht aus hervorragend ausgebildeten und motivierten Mitarbeitern, die absolute Experten auf ihrem Gebiet sind. Dank ihrer langjährigen Erfahrung in der Branche können sie jederzeit umfangreichen, erstklassigen Service für unsere Kunden anbieten.*/}
                  </div>
                </div>
	            </div>
              <div className="me-box">
                <div className="row">
                  <div className="col-lg-4">
                    <Me />
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-7">
                    <div className="title">
                        <h2>Searching for Talents</h2>
                        <p>Über <span className="orange">Orange</span> - <span className="green">Personal</span></p>
                    </div>
                    <p>
                        Orange Personal ist für Unternehmen im Mittelstand bundesweit tätig.
                    </p>
                    <p>
                        <div className="core-competence">
                            <span className="title">Der Fokus liegt in den Bereichen</span>
                            <div>
                                <ul>
                                    <li>
                                        <FontAwesomeIcon icon={faArrowRight} /> Informationstechnologie
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faArrowRight} /> Ingenieurwesen - Technik
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faArrowRight} /> Vertrieb & Marketing
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faArrowRight} /> Internet - Online Medien & Kommunikation
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faArrowRight} /> Finanzen -Buchhaltung - Controlling
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faArrowRight} /> Rechtswesen
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faArrowRight} /> Personal-Human Resources
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </p>
                    
                  </div>
                </div>
                
              </div>

              
            </div>

        <Footer />
    </div>
  );
}

export default AboutPage;
