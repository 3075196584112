import React, { useState } from 'react';
import Header from '../../components_orange/header/Header';


import JobHeader from '../../components_orange/jobheader/JobHeader';

import Footer from '../../components_orange/footer/Footer';
import './App.scss';

import img1 from './img/image-1.jpg';
import img2 from './img/image-2.jpg';
import img3 from './img/image-3.jpg';

function Company() {

  const [isOpen1, setIsOpen1] = useState(false);
  let isActive = true;
  if (isOpen1) {
    isActive = true;
  }

  return (
    <div className="container-fluid">
        <Header />
        <JobHeader
          h1="MORE FOR YOUR BUSINESS"
          h2="CAREER OPPORTUNITIES"
          name="Unternehmen"
        />
        <div className="container company">
          <div className="row img-header">
            <div className="col-lg-4"
                onMouseOver={() => {
                  setIsOpen1(!isOpen1);
                }}
                onMouseOut={() => {
                    setIsOpen1(!isOpen1);
                }}>
               {isActive
                  ? <img className="img-fluid" src={img1} alt="" />
                  : <div className="active"></div>
                }

            </div>
            <div className="col-lg-4">
              <img className="img-fluid" src={img2} alt="" />
            </div>
            <div className="col-lg-4">
              <img className="img-fluid" src={img3} alt="" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <p>
              Als unabhängiger Beratungsdienstleister, unterstützt Sie Orange Personalberatung bei der Suche nach qualifizierten Mitarbeitern.
              </p>
              <p>
              Orange Personalberatung findet für Sie nach geeigneten Bewerbern unter Zuhilfenahme von aktuellen Datenbanken,
              Netzwerken und durch Anzeigenschaltungen sowie Direktansprache.
              </p>
              <p>
              Dienstleistungen von Orange Personalberatung:
              </p>
              <p>
              • Anzeigenschaltung und Direktansprache (Personalberatung und Executive Search)<br />
              • Bewerbungsinterviews bei uns oder Ihnen<br />
              • Bewerbungstests und Assessment Center (falls gewünscht)              <br />
              • Bewerbermanagement              <br />
              • Anzeigengestützte Personalsuche<br />
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <p>
                Kernkompetenzen sind absolute Vertraulichkeit und Zuverlässigkeit.
                Besonderen Wert legen wir auf eine persönliche und individuelle Beratung.

                Dafür möchten wir Ihr Unternehmen auch gern vor Ort kennenlernen. Das hilft, die optimale Personalauswahl nicht nur nach fachlicher und persönlicher Qualifikation, sondern auch unter Berücksichtigung des gesamten Umfelds vorzunehmen.

                Das Wissen um die Produkte und Dienstleistungen Ihres Unternehmens und deren Merkmale,
                sind die wichtigste Grundlage für eine erfolgreiche Vermittlung und nachhaltige Positionierung neuer Mitarbeiter.

                Mit Ihrer Beauftragung schenken Sie Orange Personal Ihr Vertrauen.
                Wir nehmen uns viel Zeit für eine sorgfältige und umfangreiche Analyse & Beratung.

                Für den nachhaltigen Erfolg Ihres Unternehmens ist letztlich nichts wichtiger als passgenaue,

                engagierte und hochmotivierte MitarbeiterInnen.

                Orange Personal bietet Ihnen mit erfahrenen Personalberatern einen ganzheitlichen Ansatz mit dem gesamtem Spektrum an anspruchsvollen Lösungen des modernen Personalmanagements.

                Kunden unterscheiden sich hinsichtlich ihrer Vorstellung von einer gewünschten Leistung ganz erheblich.
                Deswegen können Sie bei Orange Personal einzelne Teilleistungen, wie die Vermittlung oder die Projektarbeit nutzen beziehungsweise flexibel Ihren Bedürfnissen anpassen.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <p>
                Direct Search/ Executive Search

                Da es bei der Besetzung von Positionen mit höchstem Anforderungsprofil oder mit quantitativ begrenztem  Bewerbermarkt oft nicht möglich ist, die Führungskräfte und Spezialisten über Anzeigen zu erreichen,
                sucht Orange Personal diese über die gezielte Ansprache (Direct Search).

                Orange Personal legt gemeinsam mit Ihnen das Anforderungsprofil fest, analysiert mögliche Zielgruppen
                und Positionen, und definiert passende Rekrutierungskanäle. Dazu erfolgt die Erstellung einer Wettbewerbs- und Marktanalyse.

                Nach erfolgreicher Identifikation der Kandidaten erörtert Orange Personal den Veränderungswillen sowie Möglichkeiten
                des Kandidaten und informiert und begeistert diesen für die vakante Position.

                Die persönlich geführten Kontakte und Interviews werden für einen erfolgreichen Projektablauf dokumentiert.
                Gern begleitet Orange Personal search & select den Kandidaten zum Erstgespräch.

                Ein wichtiger Bestandteil bei diesem Personalprojekt ist die kontinuierliche Betreuung und der Informationsaustausch mit den Kunden.
              </p>
            </div>
          </div>


        </div>




        <Footer />
    </div>
  );
}

export default Company;
