import React from 'react';
import Home from './pages_orange/home/Home';
import Contact from './pages_orange/contact/Contact';
import Service from './pages_orange/service/Service';
import Features from './pages_orange/features/Features';
import Jobs from './pages_orange/jobs/Jobs';
import Job from './pages_orange/job/Job';
import JobDetail from './pages_orange/job/JobDetail';
import About from './pages_orange/about/About';
import Impressum from './pages_orange/impressum/Impressum';
import Company from './pages_orange/company/Company';
import Candidate from './pages_orange/candidate/Candidate';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import logo from './logo.svg';
//import Babylon from './components-orange/Babylon';
import './App.css';

function App() {
  //const bab = new Babylon();
  //https://website-5c17d77d9d5f62-19852446.udwebsite.de/
  //console.log("DOMAIN:", window.location.hostname);
  return (
    <Router>
      <Switch>
        <Route path="/service">
          <Service />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/features">
          <Features />
        </Route>
        {/*
        <Route path="/job/:id">
          <JobDetail />
        </Route>
        <Route path="/jobs">
          <Jobs />
        </Route>
        */}
        <Route path="/company">
          <Company />
        </Route>
        <Route path="/candidate">
          <Candidate />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
