import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './about.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import sign from './img/signing.png';
import logo from './img/me.jpg';

function About() {

    return (
        <section className="about">
            <div className="container">
                <div className="row row-fit-lg fade-bottom" data-anima="" data-time="1000">
                    <div className="col-lg-6 overflow logo" style={{maxWidth: '350px'}}>
                        <a className="img-box img-box-caption btn-video lightbox" href="/about/" data-lightbox-anima="fade-top">
                            <img src={logo} alt="" />
                            <span>Alexander Rosin</span>
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <h2>ÜBER MICH</h2>
                        Willkommen bei <b>Orange Personal</b>, Ihr langfristiger strategischer Partner für erfolgreiche Karrierechancen und Personalberatung. Wir unterstützen Unternehmen dabei, ihre Personalstrategien auf lange Sicht zu optimieren, damit sie ihr volles Potenzial erreichen können. Durch unsere individuellen Dienstleistungen und unser breites Netzwerk an qualifizierten Talenten, sind wir in der Lage, Unternehmen auf ihrem Wachstumspfad zu begleiten und sicherzustellen, dass sie immer die richtigen Mitarbeiter an Bord haben. Wir sind stolz darauf, ein zuverlässiger und verlässlicher Partner zu sein, der das Wachstum und den Erfolg unserer Kunden fördert. Lassen Sie uns gemeinsam Ihre Ziele erreichen und Ihr Unternehmen wachsen.
                        <br />
                        Gerne unterstütze ich Sie weiter bei der Suche / Recruiting nach Fach & Führungskräften.<br /><br />
                        Kontaktieren Sie mich unter:<br />a.rosin@orange-personal.de
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
